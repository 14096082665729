@import "./variables";

.block {
  cursor: pointer;
  outline: none !important;

  &:hover {
    background-color: $gray-700;
  }
}
